import React from "react";
import {useI18n, WithPlaceholders} from "../../../../i18n/I18nSupport";
import {Link} from 'react-router-dom'
import {isMenuWithItems, Menu} from "./NavMenuItems";

interface SubMenuProps {
    item: Menu
    key: number
    onClick(): void
}

interface SubMenusProps {
    menu: Menu
    setShowMenu(showMenu: boolean): void
}

interface SubMenuColumnProps {
    menu: Menu
    componentStyle: string
    columnStyle: string
    subMenus: number | undefined
    onClick(): void
}

function SubMenu({item, key, onClick}: SubMenuProps) {

    let {tx} = useI18n("header.menu");

    return (
        <li className={"ad-section__product-name"} onClick={()=> onClick()}>
            <WithPlaceholders value={{
                menu: item
            }}><Link to={item.link} key={key}>
                <span className={"appigo-sub-menu-label"}>{tx`name`}</span>
            </Link>
            </WithPlaceholders>
        </li>
    )
}

function SubMenuColumn({menu, componentStyle, columnStyle, subMenus, onClick}: SubMenuColumnProps) {

    let {tx} = useI18n("header.menu");
    const runCallback = (cb: { (): JSX.Element[]; (): any; }) => {
        return cb();
    };
    return (
        <div className={componentStyle}>
            <div className="appigo-sub-menu-title dropdown-heading">
                <h6 className="font-weight-bold">{tx`name`}</h6>
                <hr className="w-75 m-0 bg-secondary" style={{float: "left"}}/>
            </div>
            <div className="appigo-sub-menu-label dropdown-a-list">
                <div className="row">
                    {!!subMenus ?
                        runCallback(() => {
                            const row = [];
                            for (var i = 0; i < subMenus; i++) {
                                row.push(
                                    <div className={columnStyle} key={`column-${i}`} >
                                        <ul>
                                            {isMenuWithItems(menu) && menu.menuItems?.filter((item, index) =>
                                                (index < (Math.ceil(menu.menuItems.length / subMenus)) * (i + 1)) && (index >= (Math.ceil(menu.menuItems.length / subMenus)) * (i))).map((item, key) =>
                                                <SubMenu item={item} key={key} onClick={onClick}/>)}
                                        </ul>
                                    </div>
                                );
                            }
                            return row;
                        }) :
                        <ul>
                            {isMenuWithItems(menu) && menu.menuItems?.map((item, key) => <SubMenu item={item}
                                                                                                  key={key} onClick={onClick}/>)}
                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}

function getColumnsCount(noOfSubMenus: number) {

    if (noOfSubMenus <= 7) {
        return 1;
    } else if (noOfSubMenus <= 20) {
        return 2;
    } else if (noOfSubMenus <= 30) {
        return 3;
    } else {
        return 4;
    }
}

export function SubMenus({menu, setShowMenu}: SubMenusProps) {

    let {tx} = useI18n("header.menu");

    if (isMenuWithItems(menu) && menu.menuItems?.length <= 7) {
        return (
            <SubMenuColumn menu={menu} componentStyle={"col-lg-7 col-xl-5"} columnStyle={"col-6"}
                           subMenus={getColumnsCount(menu.menuItems?.length)} onClick={()=> setShowMenu(false)}/>
        );
    } else if (isMenuWithItems(menu) && menu.menuItems?.length <= 20) {
        return (
            <SubMenuColumn menu={menu} componentStyle={"col-lg-7 col-xl-5"} columnStyle={"col-6"}
                           subMenus={getColumnsCount(menu.menuItems?.length)} onClick={()=> setShowMenu(false)}/>
        )
    } else if (isMenuWithItems(menu) && menu.menuItems?.length <= 30) {
        return (
            <SubMenuColumn menu={menu} componentStyle={"col-lg-7 col-xl-7"} columnStyle={"col-4"}
                           subMenus={getColumnsCount(menu.menuItems?.length)} onClick={()=> setShowMenu(false)}/>
        )
    } else {
        return (
            <SubMenuColumn menu={menu} componentStyle={"col-lg-7 col-xl-9"} columnStyle={"col-3"}
                           subMenus={getColumnsCount(isMenuWithItems(menu) ? menu.menuItems?.length : 4)} onClick={()=> setShowMenu(false)}/>
        )
    }

}