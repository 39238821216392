import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useSearchQuery} from "../../../../reducers/product/search";
import {PAGE_SIZE} from "../SearchResult/FilterSection/ResultSection";
import {isSuccess} from "../../../../reducers/networkStateReducer";
import {components} from "../../../../__generated/api";
import {useProduct} from "../../../../reducers/product/get";
import {Category, Product} from "../../../../reducers/product/types";
import {useI18n, WithPlaceholders} from "i18n/I18nSupport";
import {useProductFromSlugs} from "../../../../reducers/product/getFromSlugs";

function BreadCrumb({baseId, productSlug}: {baseId: string, productSlug: string}) {

    // const [product] = useProduct(baseId)
    const [product] = useProductFromSlugs(productSlug)
    const [, update] = useSearchQuery();
    const [productCategory, setProductCategory] = useState<Product>()

    let {tx} = useI18n('product.info.breadcrumb');

    useEffect(() => {
        if (isSuccess(product)) {
            setProductCategory(product.data)
        }
    },[product])

    useEffect(() =>{
        console.log("product slug",productCategory?.productSlug);
        console.log("product categories",productCategory?.category);
    },[productCategory])

    function createLink(category: Category): string {
        return update({
            tags: ["cat/"+category.primaryTag],
            first: PAGE_SIZE, after: 0,
            source: "search"
        })
    }

    return <nav aria-label="breadcrumb">
        <ol className="breadcrumb pt-2 pl-0 mb-0">
            {!!productCategory && productCategory.category?.map((cat,index) => <WithPlaceholders value={{
                category: cat
            }} key={index}>
                <li className="breadcrumb-item active" aria-current="page">
                    <Link to={`${createLink(cat)}`}>{tx`name`}</Link>
                </li>
            </WithPlaceholders>)}
            <WithPlaceholders value={{
                category: productCategory
            }}>
                <li className="breadcrumb-item active" aria-current="page">{tx`name`}</li>
            </WithPlaceholders>
            {/*<li className="breadcrumb-item active" aria-current="page">{!!breadcrumb && breadcrumb}</li>*/}
        </ol>
    </nav>
}

export function InfoSubHeader({baseId, productSlug}: {baseId: string, productSlug:string}) {

    return(
        <>
            <div className="page-detail-area">
                <div className="container" style={{maxWidth:"1240px"}}>
                    <div className="row">
                        <div className="col-lg-9 col-sm-7">
                            <BreadCrumb baseId={baseId} productSlug={productSlug}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
