import React, {useEffect, useState} from "react";
import Carousel from "react-bootstrap/Carousel";
import {useSearch} from "../../reducers/product/search";
import {isSuccess} from "../../reducers/networkStateReducer";
import {chunck} from "../Main/Body/Home/Widgets/Widget";
import {Product, ProductConnection} from "../../reducers/product/types";
import {useI18n, WithPlaceholders} from "../../i18n/I18nSupport";
import {ProductCard} from "./ProductCard";
import {useProfileGet} from "../../reducers/profile/get";
import {WishListItem} from "../../reducers/profile/updateWishListItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


interface RelatedProductsProps{
    related: string[],
    isAvailable?: boolean
}

export function RelatedProducts({related,isAvailable}: RelatedProductsProps) {

    const [result, search, reset] = useSearch("related")
    const [products, setProducts] = useState<ProductConnection>()
    const {tx} = useI18n("relatedProducts")

    const [profile, getProfile] = useProfileGet()
    const [curWishList, setCurWishList] = useState<WishListItem[]>()


    useEffect(() => {
        getProfile()
    },[])


    useEffect(() => {
        if (isSuccess(profile)) {
            setCurWishList(profile.data.wishList)
        }
    },[profile])


    useEffect(() => {
        search({related, first: 8, after: 0, source: "related"})
    }, [JSON.stringify(related)])

    useEffect(() => {
        if (isSuccess(result)) {
            setProducts(result.data)
        }
    },[result])

    useEffect(() => {
        if (!!products) {
            reset()
        }
    },[products])

    if(!products || products?.edges?.length === 0) {
        return <></>
    }

    return <>
        <div className="main-section pt-0 d-none d-lg-block d-xl-block">
            <div className="container product-carousel-container">
                <div className="row">
                    <div className="col-12">
                        <h4 className="font-weight-bold product-carousel-title">{tx`label`}</h4>
                    </div>
                </div>
                <div className="products-section d-none d-lg-block d-xl-block" style={{marginBottom: "1px"}}>
                    <Carousel controls={true} className={"mb-0"}
                            prevIcon={<FontAwesomeIcon className="carusel-nav-icon" icon={faChevronLeft} size={"2x"} />}
                            nextIcon={<FontAwesomeIcon className="carusel-nav-icon" icon={faChevronRight} size={"2x"} />}
                    >
                        { !!products && chunck(4, products.edges.map(v => v.node)).map((data, key) =>
                            <Carousel.Item key={key}>
                                <div className="row">
                                    { data.map((node) => <ProductCard key={node.id} item={node} isInStock={node.isInStock} isAvailable={curWishList?.map(value => value.baseId).some((v) => v == node.baseId)}/>)}
                                </div>
                            </Carousel.Item>)}
                    </Carousel>
                </div>
            </div>
        </div>

        <div className="main-section pt-0 d-lg-none d-xl-none">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h4 className="font-weight-bold pt-2">{tx`label`}</h4>
                    </div>
                </div>
                <div className="products-section" style={{marginBottom: "1px"}}>
                    <Carousel controls={false} className={"mb-2"}>
                        { !!products && chunck(2, products.edges.map(v => v.node)).map((data, key) =>
                            <Carousel.Item key={key} >
                                <div className="row">
                                    { data.map((node) => <ProductCard key={node.id} item={node} isInStock={node.isInStock} isAvailable={curWishList?.map(value => value.baseId).some((v) => v == node.baseId)}/>)}
                                </div>
                            </Carousel.Item>)}
                    </Carousel>
                </div>
            </div>
        </div>
    </>
}

