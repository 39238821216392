import React, { useEffect } from "react";
import { useStoreSelectionPopUp } from "../../../reducers/ui/storeSelectionPopUp";
import { useLocalStorage, useSessionStorage } from "../Body/Checkout/CheckoutFlowRevamp/utils";
import { useClearCart } from "../../../reducers/cart/clearCart";
import { useI18n, WithPlaceholders } from "../../../i18n/I18nSupport";
import { TemplateRes } from "../../../reducers/schema";

interface StoreSwitchPopUpProps {
    schema: TemplateRes
}

export default function StoreSwitchPopUp({ schema }: StoreSwitchPopUpProps) {

    const [storeSelectionPopup, setStoreSelectionPopup] = useStoreSelectionPopUp()
    const [getItem, _, removeItem] = useLocalStorage<{ inventoryId: string }>();
    let [state, clearCart, resetClearCart] = useClearCart();
    let { ts } = useI18n('header.inventoryLocPopUp');
    let { tx } = useI18n('header.inventoryLocPopUp');
    const [__, setSessionItem] = useSessionStorage<{ inventoryChangeEnabled: boolean }>();

    useEffect(() => {
        document.body.className = storeSelectionPopup ? "modal-open" : "";
    }, [storeSelectionPopup])

    return <div className={`modal fade ${storeSelectionPopup && 'show'}`} id="store-selection-modal" tabIndex={-1} aria-labelledby="exampleModalLabel"
        aria-hidden={!storeSelectionPopup} style={storeSelectionPopup ? { display: "block", paddingRight: "15px", background: "rgba(17,15,15,0.5)", transition: `all 5s  ease-in-out` } : {}}>
        <div className="modal-dialog">
            <div className="modal-content" style={{ marginTop: "10%", marginBottom: "0" }}>
                <div className="modal-header border-0">
                    <div className="modal-title text-center" id="exampleModalLabel1" style={{ padding: 5 }}><h4>{ts`heading`}</h4></div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={(e) => {
                            setStoreSelectionPopup(false)
                        }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <WithPlaceholders value={{
                    inventoryLocation: schema.inventoryLocations?.find((inventory) => inventory.id == getItem('inventoryId')?.inventoryId)?.name
                }}>
                    <div className="modal-body">
                        <div style={{ padding: 5 }}>
                            <span>{tx`description`}</span>
                        </div>
                    </div>
                </WithPlaceholders>
                <div className="modal-footer">
                    <button id={"clearing-cart-cancel-btn"} type="button" className="btn btn-secondary" data-dismiss="modal"
                        onClick={(e) => { setStoreSelectionPopup(false) }}>Cancel</button>
                    <button id={"clearing-cart-proceed-btn"} type="submit" className="btn btn-primary modal-footer__save-btn"
                        onClick={(e) => {
                            removeItem("inventoryId")
                            clearCart()
                            setStoreSelectionPopup(false)
                            setSessionItem('inventoryChangeEnabled',  { value: true } );
                        }}>Proceed</button>
                </div>
            </div>
        </div>
    </div>
}