import {Product} from "../../../../../reducers/product/types";
import {QuantityAction, QuantityState} from "./util";
import React, {useCallback, useEffect, useState} from "react";
import {useCartAdd} from "../../../../../reducers/cart/add";
import {useCartPopUp} from "../../../../../reducers/ui/cartPopUp";
import {useTracking} from "../../../../../tracking/trackingProvider";
import {isSuccess} from "../../../../../reducers/networkStateReducer";
import {Link, useHistory} from "react-router-dom";
import {useI18n} from "../../../../../i18n/I18nSupport";
import {useSchema} from "../../../../../reducers/schema";
import {useDefaultCurrencyCode} from "../../../../../reducers/ui/defaultCurrencyCode";
import {toastWithDanger} from "../../Cart/util";
import {keycloak} from "../../../../../auth/AuthProvider";
import {ItemType} from "../../../../../tracking/googleAnalytics";
import {useDefaultLanguage} from "../../../../../reducers/ui/defaultLanguage";

interface ProductStateSectionPartProps {
    product: Product
    state: QuantityState
    dispatch(action: QuantityAction): void
}
export function BuyNowButton({product,state}: ProductStateSectionPartProps) {
    let [cartPopUpState, setCartPopUpState] = useCartPopUp()
    const [addedStatus, doAddToCart, resetAdd] = useCartAdd("buy-now");
    const {track} = useTracking();
    const {push} = useHistory();
    const {tx} = useI18n("product.info.buyNowButtonLabel")
    const {ts} = useI18n("warnings.popUpsWarning")
    const [schema] = useSchema()
    const [currency] = useDefaultCurrencyCode();
    const [language] = useDefaultLanguage()
    const [currCurrency, setCurrCurrency] = useState<string>();
    const [isCurrencyAvailable, setIsCurrencyAvailable] = useState<boolean>(true);

    useEffect(() => {
        if (!!currency) {
            setCurrCurrency(currency?.split("|")[0])
        }
    },[currency])

    let buyNow = useCallback<() => void>(() => {
        let gaItems: ItemType[] = [{
            item_id: product.id,
            item_category: product.categoryId,
            quantity: state.quantity,
            item_name: product?.name?.[language]?.toString() ?? ""
        }]

        track({
            type: "AddToCart",
            data: {
                value: !!product.price ? product.price[currency.split("|")[0]] : 0,
                currency: currency.split("|")[0] ?? Object.keys(product.price ?? {})[0] ?? "USD",
                content_ids: product.baseId ?? '',
                content_type: product.varianceKey ?? '',
                quantity: state.quantity,
                name: product?.name?.[language]?.toString(),
                items: gaItems
            }
        });
        doAddToCart({
            selected: true,
            productId: product.baseId ?? '',
            variance: product.varianceKey ?? '',
            quantity: product.quantity == null ? 1 : state.quantity
        })
        if (cartPopUpState) {
            setCartPopUpState(!cartPopUpState)
        }
    }, [product, state]);

    useEffect(() => {
        if (isSuccess(addedStatus)){
            resetAdd()
            push("/checkout")
        }
    },[addedStatus])

    useEffect(() => {
        if (!!currCurrency) {
            if (!!product.enableSpecialPrice && !!product.specialPrice) {
                setIsCurrencyAvailable(!!product.specialPrice[currCurrency] && (product.specialPrice[currCurrency] > 0))
            } else {
                setIsCurrencyAvailable(!!product.price && !!product.price[currCurrency] && (product.price[currCurrency] > 0))
            }
        }
    },[currCurrency])

    if (isSuccess(schema)) {
        if (schema.data.generalSettings?.useSiteAsCatalog == true) {
            return <></>
        }

        if (schema.data.generalSettings?.isRegisteredUsersOnly == true) {
            if (keycloak.authenticated == null || !keycloak.authenticated) {
                return <div className="col-sm-6 mt-sm-0 mt-2 p-1">
                    <Link to={"/login"}>
                        <button
                            className={`btn btn-primary w-100 text-white ${state.available ? 'cursor-pointer' : 'alias-pointer'}`}
                            disabled={false}
                            data-toggle="modal"
                            data-target="#login-modal"
                            onClick={(e) => {
                                if ((!state.available || !product.availability || !isCurrencyAvailable) && (product.quantity != null)) {
                                    toastWithDanger(ts`outOfStock`);
                                } else {
                                    keycloak.login()
                                }
                            }}>{tx`buyNow`}
                        </button>
                    </Link>
                </div>
            }

        }

    }


    return <div className="col-sm-6 mt-sm-0 mt-2 p-1">
        <button id={"product-page-buy-now-btn"} className={`btn btn-primary w-100 text-white ${state.available ? 'cursor-pointer' : 'alias-pointer'}`}
                disabled={false}
                onClick={
                    () => {
                        if ((!state.available || !product.availability || !isCurrencyAvailable) && !(product.quantity == null)) {
                            toastWithDanger(ts`outOfStock`);
                        } else {
                            buyNow()
                        }
                    }
                }> {tx`buyNow`}</button>
    </div>
}
