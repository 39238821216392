import React, {useEffect, useState} from "react";
import {
    SideBySideMagnifier
} from 'react-image-magnifiers';
import {Product} from "../../../../../reducers/product/types";
import {createImgProxyUrl} from "../../../../common/createImageProxy";
import {LazyLoadImage} from "react-lazy-load-image-component";

interface Images{
    product : Product
}

export function ProductImageSlider({product}: Images) {

    const [selected, setSelected] = useState<string>()

    useEffect(() => {
        setSelected(createImgProxyUrl(product.imageReference === null ? (product.iconUrl ?? [])[0] : product.imageReference ?? (product.iconUrl ?? [])[0], 700, 700))
    }, [product])

    return(
        <>
            <div className="product-detail__image-slider">
                <div className={"row ml-1 mt-1 mr-1"}>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="image-1" role="tabpanel"
                             aria-labelledby="image-1-tab">
                        <span className='zoomIn' id='product-image-1'>
                            {!!selected && <SideBySideMagnifier
                                key={selected}
                                imageAlt="loading pending"
                                style={{objectFit: "contain"}}
                                imageSrc={selected}
                                className={"w-100"}
                                alwaysInPlace={true} />}
                        </span>
                        </div>
                    </div>
                    <div className={"row mt-2 ml-1 mr-1"}>
                        <ul className="nav nav-tabs border-0 image-nav-tabs" role="tablist">
                            {product.iconUrl?.map((image, index) => {
                                return (
                                    <li key={index} className="nav-item" role="presentation">
                                        <a className={`banner ${selected === image ? "nav-link active" : "nav-link"}`} id="image-1-tab" data-toggle="tab" role="tab"
                                           aria-controls="image-1" aria-selected="true">
                                            <LazyLoadImage style={{objectFit: "contain"}} onClick={() => {
                                                setSelected(createImgProxyUrl(image, 700, 700))
                                                // setBackgroundImage(image)
                                            }} src={createImgProxyUrl(image, 200, 200)}/>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
