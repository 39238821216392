import React, {useEffect, useState} from "react";
import {ProductSearchView} from "../../reducers/product/types";
import {useI18n, WithPlaceholders} from "../../i18n/I18nSupport";
import {useCartPopUp} from "../../reducers/ui/cartPopUp";
import {useProductIdState} from "../../reducers/product/setProduct";
import {Link} from "react-router-dom";
import {createImgProxyUrl} from "./createImageProxy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {definition as faHeart} from "@fortawesome/free-solid-svg-icons/faHeart";
import {definition as faHeartReg} from "@fortawesome/free-regular-svg-icons/faHeart";
import {useProfileUpdateWishList} from "../../reducers/profile/updateWishListItems";
import {isSuccess} from "../../reducers/networkStateReducer";
import {keycloak} from "../../auth/AuthProvider";
import {useSchema} from "../../reducers/schema";
import {useDefaultCurrencyCode} from "../../reducers/ui/defaultCurrencyCode";
import {toast} from "react-toastify";
import {toastCustomOptions} from "./commons";
import { LazyLoadImage } from 'react-lazy-load-image-component';


export function OutOfStockLabelling() {

    const {tx} = useI18n("product.info.stockLabel")

    return <a className="btn btn-out-of-stock"><span style={{ opacity: 1.0 }}>{tx`outOfStock`}</span></a>

    // return <div className="d-inline-block ml-1">
    //     <div className="row">
    //         <h6 className="mt-2 mb-0 font-weight-bold bg-danger text-white" style={{
    //             position: "relative",
    //             bottom: 0,
    //             alignItems: "start",
    //             fontSize: "12px"
    //         }}>{tx`outOfStock`}</h6>
    //     </div>
    //     <div className="row">
    //         <div className="d-flex flex-row" style={{alignItems: "space-between", position: "absolute"}}>
    //             <h6 className=" mt-2 mb-0 font-weight-bold"></h6>
    //         </div>
    //     </div>
    // </div>

}

type AddToCartButtonProps = {
    baseId: string,
    setCartPopupState: (open:boolean) => void,
    setProductId: (id: string, merchantId: string) => void
}

export function AddToCartButton({baseId, setCartPopupState, setProductId}: AddToCartButtonProps) {

    const {ts} = useI18n("product.info.addToCartButtonLabel")

    return <a href="javascript:void(0)"
        id={"add-to-cart-button"}
        className="btn btn-primary btn-in-stock"
        onClick={(e) => {
            setCartPopupState(true)
            setProductId(baseId, "")
        }}
    >
        {ts`add`}
        <i className="fa fa-angle-right ml-1"></i>
    </a>

}


export function BlankLabelHolder() {

    return <div className="d-inline-block ml-1">
        <div className="row">
            <h6 className="mt-2 mb-0 font-weight-bold text-white" style={{
                position: "relative",
                bottom: 0,
                alignItems: "start",
                fontSize: "12px",
            }}>&nbsp;</h6>
        </div>
        <div className="row">
            <div className="d-flex flex-row" style={{alignItems: "space-between", position: "absolute"}}>
                <h6 className=" mt-2 mb-0 font-weight-bold">&nbsp;</h6>
            </div>
        </div>
    </div>

}

export function ProductDescription() {
    let {tx} = useI18n('product.card');
    const [schema] = useSchema();

    if (isSuccess(schema)) {
        if (schema.data.generalSettings?.hideProductPrice == true) {
            return <></>
        }
    }

    return <p className="price-text"><b> {tx`price`}</b></p>

    // return <div className="d-inline-block ml-0">
    //     <div className="row">
    //         <h6 className="mt-2 mb-0 font-weight-bold price-text" style={{
    //             position: "relative",
    //             bottom: 0,
    //             alignItems: "start",
    //             fontSize: "14px"
    //         }}>{tx`price`}</h6>
    //     </div>
    //     <div className="row">
    //         <div className="d-flex flex-row" style={{
    //             alignItems: "space-between", position: "relative"
    //         }}>
    //             <h6 className="mt-2 mb-0 font-weight-bold discounted-price-text"
    //                 style={{fontSize: "14px"}}>&nbsp;</h6>
    //         </div>
    //     </div>
    // </div>
}


export function ProductDescriptionWithSpecialPrice() {
    let {tx} = useI18n('product.card');
    const [schema] = useSchema();

    if (isSuccess(schema)) {
        if (schema.data.generalSettings?.hideProductPrice == true) {
            return <></>
        }
    }

    return <p><s className="price-text">{tx`price`}</s> <b className="discounted-price-text"> {tx`specialPrice`}</b></p>

    // return <div className="d-inline-block ml-0">
    //     <div className="row">
    //         <h6 className="mt-2 mb-0 price-text" style={{
    //             position: "relative",
    //             bottom: 0,
    //             alignItems: "start",
    //             fontSize: "12px",
    //             textDecoration: "line-through"
    //         }}>{tx`price`}</h6>
    //         <h6 className="mt-2 mb-0 discounted-price-text" style={{
    //             position: "relative",
    //             bottom: 0,
    //             alignItems: "end",
    //             fontSize: "12px",
    //             textAlign: "start",
    //             marginLeft: "5px"
    //         }}>{tx`percentage`}</h6>
    //     </div>
    //     <div className="row">
    //         <div className="d-flex flex-row" style={{
    //             alignItems: "space-between", position: "relative"
    //         }}>
    //             <h6 className="mt-2 mb-0 font-weight-bold discounted-price-text"
    //                 style={{fontSize: "14px"}}>{tx`specialPrice`}</h6>
    //         </div>
    //     </div>
    // </div>
}

interface SearchResultCardProps {
    item: ProductSearchView
    isAvailable?: boolean
    isInStock?: boolean
}

interface ResponsiveCardProps {
    item: ProductSearchView,
    showCart: boolean,
    isAvailable?: boolean,
    isInStock?: boolean

}

function ResponsiveCard({item, showCart, isAvailable, isInStock}: ResponsiveCardProps) {

    let [, setCartPopupState] = useCartPopUp()
    let [, setProductId] = useProductIdState()
    const [updatedProfile, doAddToWishList, reset] = useProfileUpdateWishList()
    const [currency] = useDefaultCurrencyCode();
    const [schema] = useSchema();
    const [currCurrency, setCurrCurrency] = useState<string>();

    let {tx,ts} = useI18n('product.card');

    function checkAvailability(node: ProductSearchView): boolean {
        if (isSuccess(updatedProfile)) {
            return updatedProfile.data.wishList
                ?.map((v) => v.baseId)
                .includes(item.baseId ?? "") ?? false
        }
        return isAvailable ?? false
    }

    useEffect(() => {
        if (isSuccess(updatedProfile)) {
            return reset
        }
    }, [updatedProfile])


    useEffect(() => {
        if (!!currency && !currCurrency) {
            setCurrCurrency(currency)
        }
    }, [currency])

    function MouseOver(event: any) {
        if (item.iconUrl && item.iconUrl[1]) {
            event.target.src = createImgProxyUrl(item.iconUrl[1], 600, 615, 'fill')

        }
    }

    function MouseOut(event: any) {
        if (item.iconUrl) {
            event.target.src = createImgProxyUrl(item.iconUrl[0], 600, 615, 'fill')
        }
    }

    const productLink = !!item.productSlug ? `/products/${item.productSlug}` : `/product/${item.id}`

    return <div className="product-thumb-wrapper">
            <div className="product-img-box">
            <Link to={productLink}>
                <LazyLoadImage id={"product-card-image"} className="w-100"
                    src={createImgProxyUrl(((item.default ? (item?.imageReference ?? item?.iconUrl?.[0]) : item?.iconUrl?.[0])), 600, 615, 'fill')}
                    onMouseOver={MouseOver} 
                    onMouseLeave={MouseOut}
                />
            </Link>
            <div className="product-card-wishlist">
                <button
                    id={"product-card-wishlist-btn"}
                    className={`product-card-wishlist-btn btn btn-rounded btn-icon_sm`}
                    onClick={(e) => {
                        if (keycloak.authenticated) {
                            doAddToWishList({
                                baseId: item.baseId ?? "",
                                varianceKey: item?.varianceKey
                            });
                            toast.success(`Product ${checkAvailability(item) ? 'removed from' : 'added to'} wishlist successfully`, {
                                ...toastCustomOptions,
                                toastId: "success product"
                            });
                        } else {
                            keycloak.login()
                        }
                    }}
                    // style={{backgroundColor: 'red'}}
                >
                    <FontAwesomeIcon className="product-card-wshlist-icon" icon={checkAvailability(item) ? faHeart : faHeartReg} size={"1x"}/>
                </button>
            </div>
        </div>
        <div className="product-thumb-content">
        <Link style={{textDecoration: "none", fontSize: "13px", color: "black"}}
            to={productLink}
            className="products-section__product-name"
        >
            <h5 id={"product-card-name"}
                className={"product-card-name"}
                data-toggle="tooltip"
                title={ts`name`}
                style={{
                    display: "block",
                    width: "93%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                }}
            > {tx`name`}</h5>
        </Link>
            {!!currCurrency
                && !!PriceConversion(currCurrency, item.specialPrice)
                && !!PriceConversion(currCurrency, item.price)
                && PriceConversion(currCurrency, item.specialPrice) < PriceConversion(currCurrency, item.price) && item.enableSpecialPrice ?
                    <ProductDescriptionWithSpecialPrice/>
                :
                    <ProductDescription/>
            }

            {isSuccess(schema) && !schema.data.generalSettings?.useSiteAsCatalog &&
                ( isInStock ?
                    <AddToCartButton baseId={item.baseId ?? ""} setCartPopupState={setCartPopupState} setProductId={setProductId} />
                    :
                    <OutOfStockLabelling />
                )
            }
        </div>
    </div>

    // {/* if (keycloak.authenticated) {
    //     return <div className="card border-0 product-card-container" id={`product_card-${item.id}`}>
    //         <div className="products-section__image mb-1">
    //             {item.iconUrl &&
    //                 <Link to={!!item.productSlug ? `/products/${item.productSlug}` : `/product/${item.id}`}>
    //                     <img
    //                         src={createImgProxyUrl(((item.default ? (item.imageReference ?? item.iconUrl[0]) : item.iconUrl[0])), 600, 600)}
    //                         onMouseOver={MouseOver} onMouseLeave={MouseOut} className="w-100"/>
    //                 </Link>}
    //             {showCart && <div className="products-section__offer-bottom-actions">
    //                 <div className="row no-gutters justify-content-center text-center" style={{bottom: "-4 rem"}}>
    //                     {isSuccess(schema) && schema.data.generalSettings?.useSiteAsCatalog == true ? <></> :
    //                         <div className="col-3 mr-1">
    //                             <button className="btn btn-light btn-rounded py-2"
    //                                     onClick={(e) => {
    //                                         setCartPopupState(true)
    //                                         setProductId(item.baseId ?? "", "")
    //                                     }}>
    //                                 <FontAwesomeIcon icon={faCartPlus} size={"sm"}/>
    //                             </button>
    //                         </div>
    //                     }
    //                     <div className="col-3 ml-1">
    //                         <button
    //                             className={`btn ${(checkAvailability(item)) ? 'btn-primary' : 'btn-light'} btn-rounded py-2`}
    //                             onClick={(e) => (
    //                                 doAddToWishList({
    //                                     baseId: item.baseId ?? "",
    //                                     varianceKey: item?.varianceKey
    //                                 }),
    //                                     toast.success(`Product ${checkAvailability(item) ? 'removed from' : 'added to'} wishlist successfully`, {
    //                                         ...toastCustomOptions,
    //                                         toastId: "success product"
    //                                     })
    //                             )}>
    //                             <FontAwesomeIcon icon={faHeart} size={"sm"}/>
    //                         </button>
    //                     </div>
    //                 </div>
    //             </div>}
    //         </div>
    //         <div className="products-section__offer-bottom pb-result ml-2 mt-3">
    //             <Link style={{textDecoration: "none", fontSize: "13px", color: "black"}}
    //                   to={!!item.productSlug ? `/products/${item.productSlug}` : `/product/${item.id}`}
    //                   className="products-section__product-name">
    //                     <h6 className={"m-0 pt-3 "}  data-toggle="tooltip"  title={ts`name`} style={{
    //                         display: "block",
    //                         width: "93%",
    //                         overflow: "hidden",
    //                         whiteSpace: "nowrap",
    //                         textOverflow: "ellipsis"
    //                     }}> {tx`name`}</h6></Link>
    //             <ul className="m-0 p-0">
    //                 {/*<Rating rating={2}/>*/}
    //                 {/*<li className="d-inline">({item.rateCount})</li>*/}
    //             </ul>
    //             <div className="container d-flex flex-column">
    //                 {!!currCurrency
    //                 && !!PriceConversion(currCurrency, item.specialPrice)
    //                 && !!PriceConversion(currCurrency, item.price)
    //                 && PriceConversion(currCurrency, item.specialPrice) < PriceConversion(currCurrency, item.price) && item.enableSpecialPrice ?
    //                     (<>
    //                             <ProductDescriptionWithSpecialPrice/>
    //                             {!isInStock ? <OutOfStockLabelling/> : <BlankLabelHolder/>}
    //                         </>
    //                     ) :
    //                     (<>
    //                         <ProductDescription/>
    //                         {!isInStock ? <OutOfStockLabelling/> : <BlankLabelHolder/>}
    //                     </>)
    //                 }
    //                 {/*<div>{*/}
    //                 {/*    !isInStock ? <OutOfStockLabelling/> : <BlankLabelHolder/>*/}
    //                 {/*}*/}
    //                 {/*</div>*/}

    //             </div>
    //             {/*<h6 className="large-font mt-2 mb-0 font-weight-bold">{item.price ? CurrencyFormatter.format(item.price) : ''}</h6>*/}
    //         </div>
    //     </div>
    // } else {

    //     return <div className="card border-0" id={`product_card-${item.id}`}>
    //         <div className="products-section__image mb-1">
    //             {item.iconUrl &&
    //                 <Link to={!!item.productSlug ? `/products/${item.productSlug}` : `/product/${item.id}`}>
    //                     <img
    //                         src={createImgProxyUrl(((item.default ? (item.imageReference ?? item.iconUrl[0]) : item.iconUrl[0])), 600, 600)}
    //                         onMouseOver={MouseOver} onMouseLeave={MouseOut} className="w-100"/>
    //                 </Link>}
    //             {showCart && <div className="products-section__offer-bottom-actions">
    //                 <div className="row no-gutters justify-content-center text-center" style={{bottom: "-4 rem"}}>
    //                     {isSuccess(schema) && schema.data.generalSettings?.useSiteAsCatalog === true ? <></>
    //                         : isSuccess(schema) && schema.data.generalSettings?.isRegisteredUsersOnly === true ?
    //                             <div className="col-3 mr-1">
    //                                 <button className="btn btn-light btn-rounded py-2"
    //                                         onClick={(e) => {
    //                                             keycloak.login()
    //                                         }}>
    //                                     <FontAwesomeIcon icon={faCartPlus} size={"sm"}/>
    //                                 </button>
    //                             </div> :
    //                             <div className="col-3 mr-1">
    //                                 <button className="btn btn-light btn-rounded py-2"
    //                                         onClick={(e) => {
    //                                             setCartPopupState(true)
    //                                             setProductId(item.baseId ?? "", "")
    //                                         }}>
    //                                     <FontAwesomeIcon icon={faCartPlus} size={"sm"}/>
    //                                 </button>
    //                             </div>
    //                     }
    //                     <div className="col-3 ml-1">
    //                         <Link to={"/login"}>
    //                             <button className=" btn btn-light btn-rounded py-2" type="submit"
    //                                     onClick={(e) => {
    //                                         keycloak.login()
    //                                     }}>
    //                                 <FontAwesomeIcon icon={faHeart} size={"sm"}/>
    //                             </button>
    //                         </Link>
    //                     </div>
    //                 </div>
    //             </div>}
    //         </div>
    //         <div className="products-section__offer-bottom pb-result ml-2 mt-3">
    //             <Link style={{textDecoration: "none", fontSize: "13px", color: "black"}}
    //                   to={!!item.productSlug ? `/products/${item.productSlug}` : `/product/${item.id}`}
    //                   className="products-section__product-name">
    //                 <h6 className={"m-0 pt-3"} data-toggle="tooltip"  title={ts`name`} style={{
    //                     display: "block",
    //                     width: "93%",
    //                     overflow: "hidden",
    //                     whiteSpace: "nowrap",
    //                     textOverflow: "ellipsis"
    //                 }}>{tx`name`}</h6></Link>
    //             <ul className="m-0 p-0">
    //                 {/*<Rating rating={2}/>*/}
    //                 {/*<li className="d-inline">({item.rateCount})</li>*/}
    //             </ul>
    //             <div className="container d-flex flex-column">
    //                 {!!currCurrency
    //                 && !!PriceConversion(currCurrency, item.specialPrice)
    //                 && !!PriceConversion(currCurrency, item.price)
    //                 && PriceConversion(currCurrency, item.specialPrice) < PriceConversion(currCurrency, item.price) && item.enableSpecialPrice ?
    //                     (<>
    //                             <ProductDescriptionWithSpecialPrice/>
    //                             {!isInStock ? <OutOfStockLabelling/> : <BlankLabelHolder/>}
    //                         </>
    //                     ) :
    //                     (<>
    //                         <ProductDescription/>
    //                         {!isInStock ? <OutOfStockLabelling/> : <BlankLabelHolder/>}
    //                     </>)
    //                 }
    //                 {/*<div>{*/}
    //                 {/*    !isInStock ? <OutOfStockLabelling/> : <BlankLabelHolder/>*/}
    //                 {/*}*/}
    //                 {/*</div>*/}
    //             </div>
    //             {/*<h6 className="large-font mt-2 mb-0 font-weight-bold">{item.price ? CurrencyFormatter.format(item.price) : ''}</h6>*/}
    //         </div>
    //     </div>
    // } */}
}

export function PriceConversion(currencyCode?: string, price?: { [key: string]: number }): number {

    let code = currencyCode?.split("|")[0] ?? "USD|$"
    return !!price && !!price[code] ? price[code] : 0
}

export function ProductCard({item, isAvailable, isInStock}: SearchResultCardProps) {

    let [, setCartPopupState] = useCartPopUp()
    let [, setProductId] = useProductIdState()
    let [showCart, setShowCart] = useState<boolean>(false)
    const [currency] = useDefaultCurrencyCode();
    const [currCurrency, setCurrCurrency] = useState<string>();

    useEffect(() => {
        if (!!currency && !currCurrency) {
            setCurrCurrency(currency)
        }
    }, [currency])

    return <WithPlaceholders value={{
        product: item,
        fraction: ((PriceConversion(currCurrency, item.price) ?? 0) - (PriceConversion(currCurrency, item.specialPrice) ?? 0)) / (PriceConversion(currCurrency, item.price) ?? 0),
        percentage: Math.round((((PriceConversion(currCurrency, item.price) ?? 0) - (PriceConversion(currCurrency, item.specialPrice) ?? 0)) / (PriceConversion(currCurrency, item.price) ?? 0)) * 100),
    }}>
        <div className="col-lg-3 col-sm-6 col-6 d-none d-xl-block d-lg-block d-md-block px-1 mb-2" onMouseEnter={(e) => {
            setShowCart(true)
        }} onMouseLeave={() => {
            setShowCart(false)
        }}>
            <ResponsiveCard item={item} showCart={showCart} isAvailable={isAvailable} isInStock={isInStock}
            />
        </div>
        <div className="col-lg-3 col-sm-6 col-6 d-block d-xl-none d-lg-none d-md-none" onMouseEnter={(e) => {
            setShowCart(true)
        }} onMouseLeave={() => {
            setShowCart(false)
        }}>
            <ResponsiveCard item={item} showCart={true} isAvailable={isAvailable} isInStock={isInStock}/>
        </div>
    </WithPlaceholders>
}


