import React from 'react';
// import {ICarouselItem} from "./Carousel/CarouselItem";
import Carousel from "react-bootstrap/Carousel";
import {Link} from 'react-router-dom'
import {useSchema} from "../../../../../reducers/schema";
import {isSuccess} from "../../../../../reducers/networkStateReducer";
import {ImageViewPort} from "../../../../common/ImageViewPort";
import {useI18n, WithPlaceholders} from "../../../../../i18n/I18nSupport";

export function toLocation(percentage?: string): string {
    if (percentage === "60%") {
        return "center"
    } else if (percentage === "100%") {
        return "flex-end"
    } else {
        return "flex-start"
    }
}

export function Banner() {

    let [schema] = useSchema()
    let {tx} = useI18n("home.banner");

    if(!isSuccess(schema)) return null

    return <Carousel controls={false}>
        {schema.data.banner.items.map(({ image,
                                           link,
                                           buttonStyles,
                                           headerStyles,
                                           ...banner
                                       }, key) => {
            return <Carousel.Item key={key} interval={5000}>
                <WithPlaceholders value={{ banner }}>
                    <Link to={`${!!link ? link : '#'}`}>
                    <ImageViewPort value={image} id={"slider-image"} className={`d-block w-100 slide-image`}
                                                             style={{cursor: `${!!link ? 'pointer' : 'alias'}`}}/>
                </Link>
                    <div className={"container"}
                         style={{width: "80%", bottom: "30%", position: "absolute", marginLeft: "10%", color: "white"}}>
                        <div className="row">
                            <div className="col carousel-main-text">
                                <Link to={`${!!link ? link : '#'}`} style={{
                                    textDecoration: "none",
                                    color: "white",
                                    cursor: `${!!link ? 'pointer' : 'alias'}`
                                }}>
                                    <h1 className="main-banner-text-size font-weight-bold" style={{
                                        color: headerStyles?.fontColor,
                                        fontSize: headerStyles?.fontSize
                                    }}>{tx`caption`}</h1></Link>
                                <p className={"m-0 main-banner-description_size"}
                                   style={{cursor: "alias"}}>{tx`description`}</p>
                            </div>
                        </div>
                        <div className="row slider-button"
                             style={{justifyContent: toLocation(buttonStyles?.right), paddingLeft: "15px"}}>
                            <div className="main-banner_price-section">
                                <Link to={!!link ? link : "#"}
                                      id={"slider-banner-button"}
                                      className={`main-banner-button btn rounded-30 font-weight-bold py-sm-3 py-2 btn-sm w-100 main-banner__btn-font-size`}
                                      style={{
                                          backgroundColor: buttonStyles?.backgroundColor,
                                          color: buttonStyles?.color,
                                          display: buttonStyles?.display ? "absolute" : "none",
                                          fontSize: buttonStyles?.fontSize,
                                          cursor: `${!!link ? 'pointer' : 'alias'}`
                                      }}>
                                    {tx`buttonTitle`}
                                </Link>
                            </div>
                        </div>
                    </div>
                </WithPlaceholders>
            </Carousel.Item>
        })}
    </Carousel>;
}
