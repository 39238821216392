import React from "react";
import {ProductConnection} from "../../../../../reducers/product/types";
import {useI18n} from "../../../../../i18n/I18nSupport";
import {isMenuWithItems, Menu} from "../NavMenuItems";
import {ItemCard} from "./ItemCard";

interface RelatedItemsProps {
    products: ProductConnection
    menu: Menu
    setShowMenu(state: boolean): void
}

export function RelatedItems({products, menu, setShowMenu}: RelatedItemsProps) {

    let {tx} = useI18n("relatedProducts");

    function getBorderStyles(noOfSubMenus: number) {

        if (noOfSubMenus > 30) {
            return 2;
        } else if (noOfSubMenus > 20) {
            return 3;
        } else {
            return 4;
        }
    }

    function getColumnStyles(noOfSubMenus: number) {

        if (noOfSubMenus > 30) {
            return "col-xl-3";
        } else if (noOfSubMenus > 20) {
            return "col-xl-5";
        } else {
            return "col-xl-7";
        }
    }

    return (
        <div className={`col-lg-5 ${isMenuWithItems(menu) ? getColumnStyles(menu.menuItems?.length) : "col-xl-5"}`}>
            <div className="appigo-sub-menu-title dropdown-heading">
                <h5>{tx`label`}</h5>
                <hr className={"w-100 m-0 bg-secondary"}
                    style={{float: "left"}}/>
            </div>
            <div className="dropdown-ad-list">
                <div className="row">
                    {!!products && products.edges.slice(0, isMenuWithItems(menu) ?
                        getBorderStyles(menu.menuItems?.length) : 4).map((item, key) =>
                        <ItemCard item={item} menu={menu} key={key} onClick={()=> setShowMenu(false)}/>
                    )}
                </div>
            </div>
        </div>
    );
}