import React from 'react';
import {useI18n, WithPlaceholders} from "../../../../../i18n/I18nSupport";
import {Link} from "react-router-dom";
import {createImgProxyUrl} from "../../../../common/createImageProxy";
import {isMenuWithItems, Menu} from "../NavMenuItems";
import {useSchema} from "../../../../../reducers/schema";
import {isSuccess} from "../../../../../reducers/networkStateReducer";
import {LazyLoadImage} from "react-lazy-load-image-component";

interface ItemCardProps {
    item: any
    menu: Menu
    onClick(): void
}

export function getRelatedItemsStyles(noOfSubMenus: number) {

    if (noOfSubMenus > 30) {
        return "col-xl-7";
    } else if (noOfSubMenus > 20) {
        return "col-xl-4";
    } else {
        return "col-xl-3";
    }
}

export function ItemCard({item, menu, onClick}: ItemCardProps) {

    let {tx} = useI18n('product.card');
    const [schema] =useSchema();

    if( isSuccess(schema) && schema.data.generalSettings?.hideProductPrice == true){
        return (
            <div
                className={`${isMenuWithItems(menu) ? getRelatedItemsStyles(menu.menuItems?.length) : "col-xl-3"} col-lg-4 text-center`}>
                <WithPlaceholders value={{
                    product: item.node,
                    fraction: ((item?.node.price ?? 0) - (item?.node.specialPrice ?? 0)) / (item?.node.price ?? 0),
                    percentage: Math.round((((item?.node.price ?? 0) - (item?.node.specialPrice ?? 0)) / (item?.node.price ?? 0)) * 100),
                }}>
                    {item?.node.iconUrl && <Link to={`/product/${item?.node?.id}`} onClick={()=> onClick()}>
                        <div className="card border-0">
                            <div className="card-body p-1">
                                <div className="products-section__image">
                                    <LazyLoadImage className="w-100 rounded"
                                         src={createImgProxyUrl(((item?.node.default ? (item?.node.imageReference ?? item?.node.iconUrl[0]) : item?.node.iconUrl[0])),
                                             600, 600)}/>
                                </div>
                            </div>
                        </div>
                        <div className="appigo-sub-menu-title ad-section__product-name">{tx`name`}</div>
                    </Link>}
                </WithPlaceholders>
            </div>
        );

    }else{
        return (
            <div
                className={`${isMenuWithItems(menu) ? getRelatedItemsStyles(menu.menuItems?.length) : "col-xl-3"} col-lg-4 text-center`}>
                <WithPlaceholders value={{
                    product: item.node,
                    fraction: ((item?.node.price ?? 0) - (item?.node.specialPrice ?? 0)) / (item?.node.price ?? 0),
                    percentage: Math.round((((item?.node.price ?? 0) - (item?.node.specialPrice ?? 0)) / (item?.node.price ?? 0)) * 100),
                }}>
                    {item?.node.iconUrl && <Link to={`/product/${item?.node?.id}`} onClick={()=> onClick()}>
                        <div className="card border-0">
                            <div className="card-body p-1">
                                <div className="products-section__image">
                                    <LazyLoadImage className="w-100 rounded"
                                         src={createImgProxyUrl(((item?.node.default ? (item?.node.imageReference ?? item?.node.iconUrl[0]) : item?.node.iconUrl[0])),
                                             600, 600)}/>
                                </div>
                            </div>
                        </div>
                        <div className="appigo-sub-menu-title ad-section__product-name">{tx`name`}</div>
                        {!!item?.node?.specialPrice && !!item?.node?.price && item?.node?.specialPrice < item?.node?.price && item?.node.enableSpecialPrice ?
                            <div>
                                <div className="row ml-3">
                                    <h6 className="large-font mt-2 mb-0 text-secondary font-weight-bold price-text" style={{
                                        fontSize: "12px",
                                        textDecoration: "line-through"
                                    }}>{tx`price`}</h6>
                                    <h6 className="large-font mt-2 mb-0 text-secondary font-weight-bold discounted-price-text" style={{
                                        position: "relative",
                                        bottom: 0,
                                        alignItems: "end",
                                        fontSize: "12px",
                                        textAlign: "start",
                                        marginLeft: "5px"
                                    }}>{tx`percentage`}</h6>
                                </div>
                                <div>
                                    <h6 className="large-font mt-2 mb-0 text-secondary font-weight-bold">{tx`price`}</h6>
                                </div>
                            </div>
                            : <h6 className="large-font mt-2 mb-0 text-secondary font-weight-bold">{tx`price`}</h6>}

                    </Link>}
                </WithPlaceholders>
            </div>
        );

    }

}
