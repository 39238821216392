import React, {Reducer, useEffect, useReducer, useState} from 'react';
import {isSuccess} from "../../reducers/networkStateReducer";
import {useCartPopUp} from "../../reducers/ui/cartPopUp";
import {ProductIdState, useProductIdState} from "../../reducers/product/setProduct";
import {SideBySideMagnifier} from "react-image-magnifiers";
import {useProduct} from "../../reducers/product/get";
import {Product} from "../../reducers/product/types";
import {PriceSection, ProductVariances} from "./Body/Product/ProductInfo/ProductInformation";
import {AddToCart, ProductQuantity} from "./Body/Product/ProductInfo/ProductStateSection";
import {
    initProductState,
    productStateReducer,
    QuantityAction,
    QuantityState,
    updateProduct
} from "./Body/Product/ProductInfo/util";
import {createImgProxyUrl} from "../common/createImageProxy";
import {useI18n, WithPlaceholders} from "../../i18n/I18nSupport";
import {useProductFromSlugs} from "../../reducers/product/getFromSlugs";
import {parseUrl} from "query-string";
import {formatSlug} from "./Body/Product/Product";
import {PriceConversion} from "../common/ProductCard";
import {useDefaultCurrencyCode} from "../../reducers/ui/defaultCurrencyCode";
import {ItemType} from "../../tracking/googleAnalytics";
import {useTracking} from "../../tracking/trackingProvider";
import {useDefaultLanguage} from "../../reducers/ui/defaultLanguage";
import { LazyLoadImage } from 'react-lazy-load-image-component';



interface CartSectionProps {
    data: Product
    id: string
    setProductDetails(product: Product) : void
}

function CartSection({data, id, setProductDetails}: CartSectionProps) {

    let [state, dispatch] = useReducer<Reducer<QuantityState, QuantityAction>>(productStateReducer, initProductState);
    const [, getProductDetails] = useProduct(id)
    const [product, setProduct] = useState<Product>()
    const {tx} = useI18n("product.info")
    let [slugResp, slugFetch, slugReset] = useProductFromSlugs(`${data.id}_${data.varianceKey}` ?? "");
    const [currency] = useDefaultCurrencyCode();

    function varianceSlug(queryParams: object): string {
        return Object.entries(queryParams)?.map((param) => `${formatSlug(param[0])}=${formatSlug(param[1])}`).join("&")
    }

    useEffect(() => {
        dispatch(updateProduct(data))
        if (!isSuccess(slugResp)) {
            setProduct(data)
        }
    }, [data])

    useEffect(() => {
        return slugReset
    }, [])

    useEffect(() => {
        if (isSuccess(slugResp)) {
            dispatch(updateProduct(slugResp.data))
            setProduct(slugResp.data)
        }
    }, [slugResp])

    return <>{!!product && <WithPlaceholders value={{
        product: product ?? data,
        percentage: Math.round((((PriceConversion(currency, product?.price) ?? 0) - (PriceConversion(currency, product?.specialPrice) ?? 0)) / (PriceConversion(currency, product?.price) ?? 0)) * 100)
    }}>
        <div className="col-lg-7 col-sm-6">
            <h5 className="text-black" id="exampleModalLabel" style={{color: "black"}}>{tx`name`}</h5>
            {(!!product.brand?.name) && <p>{tx`brandLabel`}<a className="text-danger" style={{
                textDecoration: "none",
                cursor: "pointer"
            }}>{tx`brand`}</a></p>}
            <PriceSection product={product} key={`${data.id}-${data.varianceKey}`}/>
            {/*<h6 className="large-font mt-2 mb-2 font-weight-bold">{isSuccess(productVarianceInfo) ? CurrencyFormatter.format(productVarianceInfo?.data?.price ?? data.product_deprecated.price) : data.product_deprecated.price}</h6>*/}
            <ProductVariances product={product} key={`${data.id}`} onVarianceChange={varianceKey => {
                if (!!data.productSlug) {
                    slugFetch(data.productSlug, varianceKey)
                } else {
                    getProductDetails(id, varianceKey)
                }
            }}/>
            {!(!!product ? product.quantity == null : data.quantity == null) &&
            <ProductQuantity product={product ?? data} state={state} dispatch={dispatch}/>}
            <AddToCart product={product ?? data} state={state} dispatch={dispatch}/>
        </div>
    </WithPlaceholders>}
    </>
}


interface Images{
    data : Product
}

export function ProductImageSlider({data}: Images) {

    const [selected, setSelected] = useState<string>()

    useEffect(() => {
        setSelected(data.imageReference ?? (data.iconUrl ?? [])[0] ?? '')
    }, [data])

    // useEffect(() => {
    //     setSelected(selected)
    // }, [])

    return(
        <>
            <div className="col-lg-5 col-sm-6">
                {!!selected && <SideBySideMagnifier imageSrc={createImgProxyUrl(selected, 700, 700)}  alwaysInPlace={true}/>}
                <ul className="nav nav-tabs border-0 image-nav-tabs" role="tablist">
                    {(data.iconUrl ?? []).map((image, index) => {
                        return (
                            <li key={index} className="nav-item" role="presentation">
                                <a className={`banner ${selected ==image ? "nav-link active" : "nav-link"}`} id="image-1-tab" data-toggle="tab" role="tab"
                                   aria-controls="image-1" aria-selected="true">
                                    <LazyLoadImage onClick={() => {
                                        setSelected(image)
                                        // setBackgroundImage(image)
                                    }} src={createImgProxyUrl(image, 700, 700)}/>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

interface AddToCartQuickViewContentProps {
    data: ProductIdState
}

function AddToCartQuickViewContent({data}: AddToCartQuickViewContentProps) {
    let [cartPopUpState, setCartPopUpState] = useCartPopUp()
    const [productDetailsState, getProductDetails, resetProductDetails] = useProduct(data.id)
    const [productFromSlugState, getProductFromSlug, resetProductFromSlug] = useProductFromSlugs(data.id)
    let [productDetails, setProductDetails] = useState<Product>();
    const [currency] = useDefaultCurrencyCode();
    const [language] = useDefaultLanguage()
    const {track} = useTracking();


    useEffect(() => {
        if (!!data?.id) {
            getProductDetails(data.id)
        }
    },[data])

    useEffect(() => {
        if (isSuccess(productDetailsState)) {
            setProductDetails(productDetailsState.data)
            resetProductDetails()
        }
    }, [productDetailsState])

    useEffect(() => {
        if(!!productDetails?.id){
            sendTrackingProductData()
        }
        function sendTrackingProductData() {
            let gaItems: ItemType[] = [{
                item_id: productDetails?.id,
                item_category: productDetails?.categoryId,
                quantity: 1,
                item_name: productDetails?.name?.[language]?.toString() ?? ""
            }]

            track({
                type: "ViewContent",
                data: {
                    value: !!productDetails?.price ? productDetails?.price[currency.split("|")[0]] : 0,
                    currency: currency.split("|")[0],
                    content_ids: productDetails?.baseId ?? '',
                    content_type: productDetails?.varianceKey ?? '',
                    quantity: 1,
                    name: productDetails?.name?.[language]?.toString(),
                    items: gaItems
                }
            });
        }
    }, [productDetails]);


    useEffect(() => {
        document.body.className = cartPopUpState ? "modal-open" : "";
    },[cartPopUpState])

    if(!cartPopUpState) {
        return null
    }

    return <div className={`modal fade ${cartPopUpState && 'show'}`} id="add-to-cart-modal" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden={!cartPopUpState} aria-modal={cartPopUpState} style={cartPopUpState ? { display: "block", paddingRight: "15px", background: "rgba(17,15,15,0.5)", transition: `all 5s  ease-in-out`} : {}}>
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header border-0">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                            onClick={(e) => {
                                setCartPopUpState(false)
                            }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                {!!productDetails && <div className="modal-body">
                    <div className="row">
                        <ProductImageSlider data={productDetails}/>
                        <CartSection
                            data={productDetails}
                            id={data.id}
                            setProductDetails={(_data) =>
                                setProductDetails(_data)
                            }
                        />
                    </div>
                </div>}
            </div>
        </div>
    </div>

}

export default function AddToCartQuickView() {

    let [data] = useProductIdState()

    return <AddToCartQuickViewContent data={data}/>
}
