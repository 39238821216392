import React, { useEffect, useState } from "react";
import { InventoryLocation, useSchema } from "../../../../reducers/schema";
import { isSuccess } from "../../../../reducers/networkStateReducer";
import { useProfileGet } from "../../../../reducers/profile/get";
import { WishListItem } from "../../../../reducers/profile/updateWishListItems";
import { useLocalStorage, useSessionStorage } from "../Checkout/CheckoutFlowRevamp/utils";
import { setInventoryId } from "../../../../reducers/inventoryId";
import { useDispatch } from "react-redux";
import { createImgProxyUrl } from "../../../common/createImageProxy";
import { useMultiStore } from "../../../../reducers/ui/multiStoreSelection";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {keycloak} from "../../../../auth/AuthProvider";

interface MultiStoreItemProps {
    inventoryLocation: InventoryLocation
}

interface MultiStoreSelectionProps {
    inventoryLocations?: InventoryLocation[]

}

function MultiStoreItem({ inventoryLocation }: MultiStoreItemProps) {

    const [getItem, setItem] = useLocalStorage<{ inventoryId: string }>();
    const dispatch = useDispatch();
    const [_, setMultiSelectionEnabled] = useMultiStore();
    const [__, setSessionItem] = useSessionStorage<{ inventoryChangeEnabled: boolean }>();

    return <div className="address-btn cursor-pointer" id="button-2"
        onClick={(e) => {
            setMultiSelectionEnabled(false);
            setItem(`inventoryId`, { value: { inventoryId: inventoryLocation.id } });
            dispatch(setInventoryId(getItem('inventoryId').inventoryId));
            setSessionItem('inventoryChangeEnabled',  { value: false } );
        }}
        style={{
            marginLeft: 10,
            borderColor: "#0A246A",
            borderStyle: 'inset',
            borderWidth: 1,
            padding: 15,
            borderLeftWidth: 4,
            borderLeftColor: 'gray'
        }}>
        <a className="address-link" href="" style={{ color: "black" }}>
            <p className="font-weight-bold mb-2">{inventoryLocation?.name}</p>
            {inventoryLocation?.address?.addressLine1}
            <br />
            {inventoryLocation?.address?.city}
            <br />
            {inventoryLocation?.address?.province}
            <br />
            {inventoryLocation?.address?.contactNumber}{inventoryLocation?.address?.contactNumber2 ? `, ${inventoryLocation?.address?.contactNumber2}` : ""}
        </a>
    </div>
}

export function MultiStoreSelection({ inventoryLocations }: MultiStoreSelectionProps) {

    const [profile, getProfile] = useProfileGet()
    const [curWishList, setCurWishList] = useState<WishListItem[]>()
    const [schema] = useSchema();
    const [_, setMultiSelectionEnabled] = useMultiStore();
    const [getItem, setItem] = useLocalStorage<{ inventoryId: string }>();
    const [getSessionItem, setSessionItem] = useSessionStorage<{ inventoryChangeEnabled: boolean }>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (keycloak.authenticated && (isSuccess(profile) && profile.data.merchantId == null)) {
            getProfile()
        }

        if (!getItem('inventoryId')?.inventoryId && inventoryLocations?.length && inventoryLocations[0].id && !getSessionItem('inventoryChangeEnabled')) {
            setMultiSelectionEnabled(false);
            setItem(`inventoryId`, { value: { inventoryId: inventoryLocations[0].id } })
            dispatch(setInventoryId(getItem('inventoryId').inventoryId))
            setSessionItem('inventoryChangeEnabled',  { value: false } )
        }
    }, [])

    useEffect(() => {
        if (isSuccess(profile)) {
            setCurWishList(profile.data.wishList)
        }
    }, [profile])

    useEffect(() => {
        console.log(curWishList)
    }, [curWishList])

    return (<>
        <div className="store-select">
            <div className="container" style={{ marginTop: 100, marginBottom: 25 }}>
                <div className="card pr-2">
                    <div className="row">
                        <div className="col-lg-7 pr-2">
                            <LazyLoadImage src={isSuccess(schema) ? createImgProxyUrl(schema.data.generalSettings?.landingImg, 700, 700) : ""} className="" alt="FAIL"
                                style={{ objectFit: 'fill', width: '100%', height: '100%' }} />
                        </div>
                        <div className="col-lg-5 p-0 pr-2">
                            <div className="p-2 pt-3 ">
                                <div className="row m-2">
                                    <h6 className="font-weight-bold">Choose the shop location</h6>
                                </div>
                                <div className="row m-2">
                                    <p>Choosing a location from our inventory below, you will be able to add items
                                        to
                                        cart from that particular location.</p>
                                </div>
                                <div style={{ height: '380px', overflowY: 'scroll' }}>
                                    {inventoryLocations?.map((inventory) => {
                                        return <MultiStoreItem inventoryLocation={inventory} key={inventory.id} />
                                    })}
                                </div>
                                <div className="row m-2 mt-3">
                                    <p className="light">*You will be able to change the inventory location you want
                                        to
                                        order from as and when you need</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </>
    )
}

export default MultiStoreSelection